import styled from '@emotion/styled'
import React from 'react'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.a`
  width: fit-content;

  display: flex;
  height: 56px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 12px;

  border-radius: 50px;
  background: #064a79;

  color: #fff;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-decoration: none;
`

const Icon = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  padding: 3px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 4px;
  background: #fff;
`

export default function ConnectWithLinkedin() {
  const { t } = useTranslation()

  return (
    <Wrapper href="https://www.linkedin.com/company/ichigoinc/" target="_blank">
      <Icon>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22"
          height="20"
          viewBox="0 0 22 20"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.8346 20H17.3828V13.0008C17.3828 11.0818 16.5929 10.0094 14.9474 10.0094C13.1574 10.0094 12.2222 11.1254 12.2222 13.0008V20H7.93186V6.66667H12.2222V8.46267C12.2222 8.46267 13.5122 6.25931 16.5774 6.25931C19.6412 6.25931 21.8346 7.98634 21.8346 11.5582V20ZM2.81353 4.92077C1.35216 4.92077 0.167969 3.8191 0.167969 2.46039C0.167969 1.10168 1.35216 0 2.81353 0C4.2749 0 5.45839 1.10168 5.45839 2.46039C5.45839 3.8191 4.2749 4.92077 2.81353 4.92077ZM0.598199 20H5.07189V6.66667H0.598199V20Z"
            fill="#064A79"
          />
        </svg>
      </Icon>

      {t('generic.connect_with_linkedin')}
    </Wrapper>
  )
}
