import { SectionContainer } from '@/components/styled/Container'
import { DeviceType, useDeviceType } from '@/hooks/useDeviceType'
import { media } from '@/styles/media'
import { motion, useScroll, useTransform } from 'framer-motion'
import React, { useEffect, useRef, useState } from 'react'

import Image1 from '@/assets/images/about/gallery/01.jpg'
import Image2 from '@/assets/images/about/gallery/02.jpg'
import Image3 from '@/assets/images/about/gallery/03.jpg'
import Image4 from '@/assets/images/about/gallery/04.jpg'
import Image5 from '@/assets/images/about/gallery/05.jpg'
import Image6 from '@/assets/images/about/gallery/06.jpg'
import Image7 from '@/assets/images/about/gallery/07.jpg'
import Image8 from '@/assets/images/about/gallery/08.jpg'
import Image9 from '@/assets/images/about/gallery/09.jpg'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

const Wrapper = styled(motion.div)`
  overflow-x: auto;
  overflow-y: hidden;
  z-index: 2;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  ${media.tabletLandscapeDown} {
    margin-top: -100px;
  }
`

const Montage = styled(motion.div)`
  padding: 60px 0 0 0;

  width: 100%;
  max-width: 100%;
  min-height: fit-content;
  max-height: fit-content;

  display: grid;
  grid-template:
    'item1 item3 item3 item4 item6 item8 item8'
    'item2 item3 item3 item5 item7 item8 item8';
  gap: 24px;

  ${media.tabletLandscapeDown} {
    padding: 0;
    gap: 12px;

    grid-template:
      'item1 item2 item4 item7 item7'
      'item3 item3 item5 item7 item7'
      'item3 item3 item6 item8 item9';
  }
`

const Image = styled.img`
  border-radius: 12px;
  object-fit: cover;

  &.square-image {
    width: 360px;
    height: 298px;
  }
  &.portrait-image {
    width: 600px;
    height: 100%;
  }

  ${media.tabletLandscapeDown} {
    &.square-image {
      width: 135px;
      height: 140px;
    }
    &.portrait-image {
      width: 100%;
      height: 100%;
    }
  }

  &.item-1 {
    grid-area: item1;
  }

  &.item-2 {
    grid-area: item2;
  }

  &.item-3 {
    grid-area: item3;
  }

  &.item-4 {
    grid-area: item4;
  }

  &.item-5 {
    grid-area: item5;
  }

  &.item-6 {
    grid-area: item6;
  }

  &.item-7 {
    grid-area: item7;
  }

  &.item-8 {
    grid-area: item8;
  }

  &.item-9 {
    grid-area: item9;
  }
`

export default function HowWeWork() {
  const { deviceType, width: deviceWidth } = useDeviceType()
  const [translateX, setTranslateX] = useState(['', ''])

  useEffect(() => {
    let start = ''
    let end = ''

    if (deviceType === DeviceType.Mobile) {
      end = (5.5).toFixed(2)
      start = (-0.325 * deviceWidth + 216.55).toFixed(2)

      setTranslateX([`-${start}%`, `${end}%`])
    } else {
      end = (-0.0024375 * deviceWidth + 10.18).toFixed(2)
      start = (-0.089583 * deviceWidth + 200.99936).toFixed(2)

      setTranslateX([`-${start}%`, `${end}%`])
    }
  }, [deviceWidth])

  const ref = useRef(null)

  const { scrollYProgress } = useScroll({
    target: ref
  })

  const range = deviceType === DeviceType.Mobile ? [0.5, 0.8] : [0, 1]

  const x = useTransform(scrollYProgress, range, translateX)

  return (
    <SectionContainer
      customCss={css`
        z-index: 2;
        background: #002e4e;
        background-blend-mode: 'overlay, multiply, normal';
        padding: 0 !important;
      `}
    >
      <Wrapper ref={ref}>
        {deviceType === DeviceType.Mobile ? (
          <Montage style={{ x }}>
            <Image src={Image1} className="item-1 square-image" />
            <Image src={Image2} className="item-2 square-image" />
            <Image src={Image3} className="item-3 portrait-image" />
            <Image src={Image4} className="item-4 square-image" />
            <Image src={Image6} className="item-6 square-image" />
            <Image src={Image5} className="item-5 square-image" />
            <Image src={Image8} className="item-7 portrait-image" />
            <Image src={Image7} className="item-8 square-image" />
            <Image src={Image9} className="item-9 square-image" />
          </Montage>
        ) : (
          <Montage style={{ x }}>
            <Image src={Image1} className="item-1 square-image" />
            <Image src={Image2} className="item-2 square-image" />
            <Image src={Image3} className="item-3 portrait-image" />
            <Image src={Image4} className="item-4 square-image" />
            <Image src={Image5} className="item-5 square-image" />
            <Image src={Image6} className="item-6 square-image" />
            <Image src={Image7} className="item-7 square-image" />
            <Image src={Image8} className="item-8 portrait-image" />
          </Montage>
        )}
      </Wrapper>
    </SectionContainer>
  )
}
